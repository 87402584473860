<template>
  <div class="Vehicles">
      <TrackerLocation license-plate="TM37FUG" imei="6028024308" :date="current_date"/>
  </div>
</template>

<script>
// @ is an alias to /src
import TrackerLocation from '@/components/TrackerLocation.vue'

export default {
    name: 'Vehicles',
    components: {    
        TrackerLocation
    },
    data: () => ({
        current_date: new Date().toISOString().slice(0,10)
    })  
}
</script>

<style>

</style>